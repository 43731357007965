import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { COLOR } from '@src/styles/color';
import apis from '@src/apis';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { regexEmail, regexName, regexPassword } from '@src/constants/regex';
import { ReactComponent as PasswordIcon } from '@src/assets/icons/password.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {
  ArrowBack,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import DeleteUser from './DeleteUser';
import { StyleCreateUser } from './index.style';
import SearchUserAutocomplete from './SearchUserAutocomplete';

const INITIAL_STATE = {
  name: '',
  email: '',
  password: '',
  roleId: '',
};

const CreateAdminPopup = ({ open, onClose, item, roles, setListData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(INITIAL_STATE);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAlreadyHasAccount, setIsAlreadyHasAccount] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [userId, setUserId] = useState('');
  const isEdit = !!item;

  const roleSelected = roles.find((role) => role.id === data.roleId);
  const displayDescription = roleSelected && !!roleSelected.description.length;

  const validate = () => {
    if (isAlreadyHasAccount && (!userId || !data.roleId)) {
      setIsError(true);
      return false;
    }

    if (!isAlreadyHasAccount) {
      if (!data.name || !data.email || !data.roleId) {
        setIsError(true);
        return false;
      }
      if (!regexName.test(data.name)) {
        setIsError(true);
        return false;
      }
      if (!regexEmail.test(data.email)) {
        setIsError(true);
        return false;
      }
      if (!isEdit) {
        if (!data.password || !confirmPassword) {
          setIsError(true);
          return false;
        }
        if (!regexPassword.test(data.password)) {
          setIsError(true);
          return false;
        }
        if (data.password !== confirmPassword) {
          setIsError(true);
          return false;
        }
      }
    }
    setIsError(false);
    return true;
  };

  const handleReset = () => {
    const dataReset = isEdit
      ? {
          name: item.name,
          email: item.email,
          roleId: item.systemUser.roleId,
        }
      : INITIAL_STATE;
    setData(dataReset);
    setConfirmPassword('');
    setIsError(false);
    setUserId('');
    onClose();
  };

  const getSubmitText = () => {
    if (isEdit) return t('save');
    if (isAlreadyHasAccount) return t('Thêm');
    return t('createNow');
  };

  const handleSubmit = async () => {
    try {
      if (!validate()) return;
      setIsSubmitting(true);
      if (isEdit) {
        const res = await apis.admin.updateUser(item.id, {
          roleId: data.roleId,
        });
        if (!res) throw new Error();
        setListData((prevData) => ({
          ...prevData,
          admins: prevData.admins.map((admin) =>
            admin.id === item.id
              ? {
                  ...admin,
                  systemUser: {
                    ...admin.systemUser,
                    roleId: data.roleId,
                  },
                }
              : admin,
          ),
        }));
        setIsError(false);
        enqueueSnackbar(t('updateUserSuccess'), { variant: 'success' });
      } else {
        let res;
        if (isAlreadyHasAccount)
          res = await apis.admin.promoteUserToAdmin(userId, {
            roleId: data.roleId,
          });
        else res = await apis.admin.createUser(data);

        if (!res) throw new Error();
        setListData((prevData) => ({
          ...prevData,
          admins: [res?.result, ...prevData.admins],
          pagination: {
            ...prevData.pagination,
            total: prevData.pagination.total + 1,
          },
        }));
        handleReset();
        enqueueSnackbar(t('createUserSuccess'), { variant: 'success' });
      }
      onClose();
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const redirectToAlreadyHasAccount = () => {
    setUserId('');
    setIsAlreadyHasAccount(false);
  };

  useEffect(() => {
    if (item)
      setData({
        name: item.name,
        email: item.email,
        roleId: item.systemUser.roleId,
      });
  }, [item]);

  return (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{ sx: { borderRadius: 4 } }}
      onClose={handleReset}
    >
      <Box padding={4} display="flex" flexDirection="column" gap={2}>
        {isAlreadyHasAccount ? (
          <Box>
            <Box display="flex" alignItems="center">
              <IconButton onClick={redirectToAlreadyHasAccount} color="primary">
                <ArrowBack />
              </IconButton>
              <Typography variant="h3" mr="auto">
                {t('promoteToAdmin')}
              </Typography>
              <Button
                onClick={redirectToAlreadyHasAccount}
                sx={{
                  textDecoration: 'underline',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {t('doNotHaveAccount')}
              </Button>
            </Box>
            <Typography variant="body2" color={COLOR.darkestBlue[100]}>
              {t('chooseUserDesc')}
            </Typography>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3">
              {isEdit ? t('updateUser') : t('addAdmin')}
            </Typography>
            {!isEdit && (
              <Button
                onClick={() => setIsAlreadyHasAccount(true)}
                sx={{
                  textDecoration: 'underline',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {t('alreadyHaveAccount')}
              </Button>
            )}
          </Box>
        )}
        {isAlreadyHasAccount ? (
          <StyleCreateUser>
            <SearchUserAutocomplete
              userId={userId}
              setUserId={setUserId}
              isError={isError}
            />
          </StyleCreateUser>
        ) : (
          <StyleCreateUser gridTemplateColumns="repeat(2, 1fr)">
            <TextField
              variant="outlined"
              fullWidth
              className="textfield-input"
              placeholder={t('name')}
              value={data.name}
              autoComplete="new-name"
              onChange={(e) => setData({ ...data, name: e.target.value })}
              disabled={isEdit}
              error={isError && (!data.name || !regexName.test(data.name))}
              helperText={
                isError &&
                (!data.name || !regexName.test(data.name)) &&
                t('nameInvalid')
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faUser}
                      color={COLOR.darkBlue[100]}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              fullWidth
              className="textfield-input"
              placeholder={t('email')}
              value={data.email}
              autoComplete="new-email"
              onChange={(e) => setData({ ...data, email: e.target.value })}
              disabled={isEdit}
              error={isError && (!data.email || !regexEmail.test(data.email))}
              helperText={
                isError &&
                (!data.email || !regexEmail.test(data.email)) &&
                t('invalidEmail')
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      color={COLOR.darkBlue[100]}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {!isEdit && (
              <>
                <TextField
                  variant="outlined"
                  type={isShowPassword ? '' : 'password'}
                  fullWidth
                  className="textfield-input"
                  placeholder={t('password')}
                  value={data.password}
                  autoComplete="new-password"
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => setIsShowPassword(!isShowPassword)}
                      >
                        {isShowPassword ? (
                          <VisibilityOffOutlined
                            fontSize="small"
                            cursor="pointer"
                          />
                        ) : (
                          <VisibilityOutlined
                            fontSize="small"
                            cursor="pointer"
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  error={
                    isError &&
                    (!data.password || !regexPassword.test(data.password))
                  }
                  helperText={
                    isError &&
                    (!data.password || !regexPassword.test(data.password)) &&
                    t('invalidPassword')
                  }
                />
                <TextField
                  variant="outlined"
                  type={isShowConfirmPassword ? '' : 'password'}
                  fullWidth
                  className="textfield-input"
                  placeholder={t('confirmPassword')}
                  value={confirmPassword}
                  autoComplete="new-password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() =>
                          setIsShowConfirmPassword(!isShowConfirmPassword)
                        }
                      >
                        {isShowConfirmPassword ? (
                          <VisibilityOffOutlined
                            fontSize="small"
                            cursor="pointer"
                          />
                        ) : (
                          <VisibilityOutlined
                            fontSize="small"
                            cursor="pointer"
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  error={isError && data.password !== confirmPassword}
                  helperText={
                    isError &&
                    data.password !== confirmPassword &&
                    t('passwordNotMatch')
                  }
                />
              </>
            )}
          </StyleCreateUser>
        )}
        <Box>
          <Typography variant="h6" mb={1}>
            {t('role')}
          </Typography>
          <RadioGroup
            value={data.roleId}
            onChange={(e) => setData({ ...data, roleId: e.target.value })}
          >
            <Box
              display="grid"
              gridTemplateColumns="repeat(2, 1fr)"
              columnGap={2}
            >
              {roles
                .filter(
                  (role) => !role.isMasterRole || role.isMasterRole === false,
                )
                .map((role) => (
                  <FormControlLabel
                    key={role.id}
                    value={role.id}
                    control={<Radio size="small" />}
                    label={role.name}
                  />
                ))}
            </Box>
          </RadioGroup>
          {isError && !data.roleId && (
            <Typography variant="caption" color={COLOR.red[100]}>
              {t('required')}
            </Typography>
          )}
        </Box>
        {displayDescription && (
          <Box>
            <Typography variant="h6" mb={1}>
              {t('permission')}
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns="repeat(2, 1fr)"
              columnGap={2}
              rowGap={1}
            >
              {roleSelected.description.map((des) => (
                <Box key={uuidv4()} display="flex" alignItems="center">
                  <Box
                    width={4}
                    height={4}
                    borderRadius="50%"
                    bgcolor={COLOR.blueGray[80]}
                    ml={0.5}
                    mr={1}
                    flexShrink={0}
                  />
                  <Typography variant="body2" color={COLOR.blueGray[80]}>
                    {des}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box mt={2} display="flex" gap={1} justifyContent="end">
          {isEdit && (
            <Box mr="auto">
              <DeleteUser
                id={item.id}
                name={item.name}
                setListData={setListData}
                onClose={onClose}
              />
            </Box>
          )}
          <Button variant="outlined" onClick={handleReset} size="large">
            {isEdit ? t('reset') : t('cancel')}
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            loading={isSubmitting}
            variant="contained"
            size="large"
          >
            {getSubmitText()}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateAdminPopup;
