import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import camelcase from 'camelcase';
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Search, Sync } from '@mui/icons-material';
import debounce from '@src/utils/debounce';
import apis from '@src/apis';
import { downloadExcel } from '@src/services/download';
import OrganizationSearch from '@src/components/OrganizationSearch';
import { useSearchParams } from '@src/hooks';
import {
  ALL,
  DATE_TIME_PICKER_TYPES,
  TRANSACTION_ACTION,
  START_TIME_DEFAULT,
  END_TIME_DEFAULT,
} from '@src/constants';
import { CustomDatePickerRange } from '@src/components/CustomDatePickerRange';
import { COLOR } from '@src/styles/color';
import { StyleFilterUser } from '../AdminManage/index.style';

const FilterHistoryTransaction = ({ filter, setFilter }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { t } = useTranslation();
  const { params } = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [inputText, setInputText] = useState(filter.inputText);

  const handleChangeAction = (e) =>
    setFilter({ ...filter, action: e.target.value });

  const onChangeStartDate = (value) =>
    setFilter((prevState) => ({ ...prevState, startTime: value }));

  const onChangeEndDate = (value) =>
    setFilter((prevState) => ({ ...prevState, endTime: value }));

  const { organizationId } = params;

  const onReloadData = () =>
    setFilter((prevState) => ({
      ...prevState,
      status: ALL,
      action: ALL,
      startTime: START_TIME_DEFAULT,
      endTime: END_TIME_DEFAULT,
    }));

  const handleChangeInputText = (e) => {
    setInputText(e.target.value);
    debounce(() => setFilter({ ...filter, inputText: e.target.value }))();
  };
  const handleExportReport = async () => {
    try {
      setDownloadLoading(true);
      const queryParams = {
        inputText: filter.inputText,
      };

      if (filter.action !== ALL) queryParams.action = filter.action;
      if (filter.startTime) queryParams.startTime = filter.startTime;
      if (filter.endTime) queryParams.endTime = filter.endTime;
      if (filter.organizationId)
        queryParams.organizationId = filter.organizationId;
      const res = await apis.report.exportHistoryTransaction(queryParams);
      if (res?.data)
        downloadExcel(
          res.data,
          `Baocao_${moment(filter.startTime).format('DD-MM-YYYY')}_${moment(
            filter.endTime,
          ).format('DD-MM-YYYY')}`,
        );
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setDownloadLoading(false);
    }
  };

  useEffect(() => {
    setInputText(filter.inputText);
  }, [filter.inputText]);

  useEffect(() => {
    setFilter((prevState) => ({ ...prevState, organizationId }));
  }, [organizationId]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1.5}
      >
        <Typography variant="h3">{t('historyTransaction')}</Typography>
        <LoadingButton
          variant="contained"
          sx={{ whiteSpace: 'nowrap' }}
          onClick={handleExportReport}
          loading={downloadLoading}
        >
          Xuất báo cáo
        </LoadingButton>
      </Box>
      <StyleFilterUser justifyContent="space-between">
        <TextField
          size="small"
          variant="outlined"
          className="input-search"
          sx={{ minWidth: '440px', width: '100%' }}
          placeholder={t('searchHistoryTransactionPlaceholder')}
          value={inputText}
          onChange={handleChangeInputText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        <Box minWidth="180px">
          <OrganizationSearch />
        </Box>
        <TextField
          select
          variant="outlined"
          size="small"
          label={t('action')}
          className="select"
          sx={{
            '.MuiInputLabel-root': {
              color: `${COLOR.darkestBlue[100]} !important`,
              opacity: '1 !important',
            },
          }}
          value={filter.action}
          onChange={handleChangeAction}
        >
          <MenuItem value={ALL}>{t('all')}</MenuItem>
          {Object.keys(TRANSACTION_ACTION).map((action) => (
            <MenuItem key={action} value={action}>
              {t(camelcase(action))}
            </MenuItem>
          ))}
        </TextField>
        <CustomDatePickerRange
          startDate={filter.startTime}
          endDate={filter.endTime}
          handleChangeStartDate={onChangeStartDate}
          handleChangeEndDate={onChangeEndDate}
          type={DATE_TIME_PICKER_TYPES.DATE}
          isRefresh={false}
          hasFromText={false}
          width={140}
        />
        <Tooltip title={t('reloadData')}>
          <IconButton onClick={onReloadData}>
            <Sync />
          </IconButton>
        </Tooltip>
      </StyleFilterUser>
    </>
  );
};

export default FilterHistoryTransaction;
