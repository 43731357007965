import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import camelcase from 'camelcase';
import CustomTable from '@src/components/CustomTable';
import { formatNumber } from '@src/utils/formatNumber';
import {
  TRANSACTION_ACTION,
  DEFAULT_APP_ICON,
  DATE_TIME_FORMAT,
} from '@src/constants';

import { Avatar, Box, Chip, Typography } from '@mui/material';

const TRANSACTION_SYNTAX_MAP = {
  [TRANSACTION_ACTION.REGISTER]: 'DK',
  [TRANSACTION_ACTION.CANCEL]: 'HUY',
};

const renderActionColor = (action) => {
  switch (action) {
    case TRANSACTION_ACTION.REGISTER:
      return 'success';
    case TRANSACTION_ACTION.CANCEL:
      return 'error';
    default:
      return 'default';
  }
};

const ListHistoryTransaction = ({
  data = [],
  pagination,
  handleChangePage,
  loading,
}) => {
  const { t } = useTranslation();

  const heads = [
    { label: t('STT'), valueName: 'index', align: 'center' },
    { label: t('application'), valueName: 'application', align: 'left' },
    { label: t('emailOrPhoneNumber'), valueName: 'contact', align: 'left' },
    { label: t('sessionId'), valueName: 'sessionId', align: 'center' },
    { label: t('packageCode'), valueName: 'packageCode', align: 'left' },
    { label: t('syntax'), valueName: 'syntax', align: 'left' },
    { label: t('money'), valueName: 'chargePrice', align: 'right' },
    { label: t('transactionTime'), valueName: 'purchaseDate', align: 'left' },
    { label: t('paymentChannel'), valueName: 'channel', align: 'center' },
    { label: t('action'), valueName: 'action', align: 'left' },
  ];

  const formatData = data?.map((transaction = {}, index) => ({
    ...transaction,
    index,
    purchaseDate: (
      <div className="nowrap">
        {moment(transaction.purchaseDate).format(DATE_TIME_FORMAT)}
      </div>
    ),
    packageName: <div className="nowrap">{transaction.package?.name}</div>,
    packageCode: (
      <div className="nowrap">{transaction.package?.packageCode}</div>
    ),
    chargePrice: formatNumber(transaction.chargePrice || 0),
    contact: transaction.user?.email || transaction.user?.phoneNumber,
    action: (
      <Chip
        label={t(camelcase(transaction.action || ''))}
        color={renderActionColor(transaction.action)}
      />
    ),
    application: (
      <Box display="flex" alignItems="center" gap={1}>
        <Avatar
          variant="rounded"
          src={transaction?.imageUrl || DEFAULT_APP_ICON}
          alt={transaction.app?.name}
          sizes="small"
        />
        <Typography variant="body2" className="nowrap">
          {transaction.app?.name}
        </Typography>
      </Box>
    ),
    syntax: (
      <div className="nowrap">
        {TRANSACTION_SYNTAX_MAP[transaction.action]}{' '}
        {transaction.package?.packageCode}
      </div>
    ),
  }));

  return (
    <CustomTable
      items={formatData}
      heads={heads}
      pagination={pagination}
      onChangePagination={handleChangePage}
      loading={loading}
      maxHeight="calc(100vh - 210px)"
      isStickyHeader
    />
  );
};

export default ListHistoryTransaction;
