import React from 'react';
import { StyledTooltip } from './index.style';

const Tooltip = ({ title, children, ...props }) => (
  <StyledTooltip title={title} {...props}>
    {children}
  </StyledTooltip>
);

export default Tooltip;
